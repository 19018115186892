import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import Logo from "../../../assets/netmixx_logo_rb.png";
import axios from "axios";

export const Mixed = () => {
	const navigate = useNavigate();
	// Inside your component
	const location = useLocation();
	const { audio = null, batch_id = null } = location.state?.result || {};
	const product = location.state?.product;
	console.log(product);
	// console.log("Here is the audio and batch id url:", location, audio, batch_id);

	const [email, setEmail] = useState("");
	const [description, setDescription] = useState("");
	const [showAlert, setShowAlert] = useState(false);
	const [remix, setRemix] = useState(false);
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (showAlert) {
			// Navigate to home
			navigate("/", { state: { showAlert: true } });

			// // After navigating to home, clear the alert after 3 seconds
			// setTimeout(() => {
			// 	setShowAlert(false);
			// }, 5000);
		}
	}, [showAlert, navigate]);

	const handleReMixAudio = async (e) => {
		e.preventDefault();
		setIsLoading(true)

		console.log("click")

		const payload = {
			stripe_email: email,
			description: description,
			batch_id: batch_id,
		};

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/remix`,
				payload,
				{
					headers: { "Content-Type": "application/json" },
				}
			);
			console.log("Here is the remix response", response);
			setShowAlert(true);
			setIsLoading(false)

		} catch (error) {
			setIsLoading(false);
			console.log("Here is the remix error:", error);
		}
	};

	return (
		<DashboardLayout>
			

			<div className="inner-container">
				<header>
					<h1>{remix ? "Enter Remix Detail" : "Your Track is Ready!"}</h1>
				</header>
				<main>
					{remix ? (
						<div
							className="upload-container remix-form"
							id="upload-form"
						>
							<div className="remix-form-group">
								<label htmlFor="email">Email Address</label>
								<input
									type="email"
									id="email"
									name="email"
									placeholder="Email"
									value={email}
									required
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className="remix-form-group">
								<label htmlFor="description">Remix Description</label>
								<textarea
									type="text"
									id="description"
									name="description"
									value={description}
									placeholder="Enter the changes you need"
									rows="5"
									required
									onChange={(e) => setDescription(e.target.value)}
								/>
							</div>
							<button
								id="purchase-btn"
								className="btn btn-secondary"
								disabled={!email && !description}
								onClick={handleReMixAudio}
							>
								{isLoading ? "Processing..." : "Submit"}
							</button>
						</div>
					) : (
						<div>
							<div className="player">
								<div className="track-info">
									<img src={Logo} alt="Album Art" className="album-art" />
									<div className="track-details">
										<h2 className="track-title">
											{product === "single_track_master"
												? "Mastered Track"
												: "Mixxed Track"}
										</h2>
										<p className="track-artist">Netmixx</p>
									</div>
								</div>
								<audio controls controlsList="nodownload">
									<source src={audio} type="audio/mpeg" />
									Your browser does not support the audio element.
								</audio>
							</div>
							<div className="actions">
								{product === "mix_and_master" && (
									<button
										className="btn btn-primary"
										onClick={() => setRemix(true)}
									>
										Mixx Again
									</button>
								)}

								<button
									id="purchase-btn"
									className="btn btn-secondary"
									onClick={() =>
										navigate(
											`/mixxed/stripe-payment?batch_id=${batch_id}&productType=${product}`
										)
									}
								>
									Purchase and Download
								</button>
							</div>
						</div>
					)}
				</main>
			</div>
		</DashboardLayout>
	);
};
