import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route
} from "react-router-dom";
import { Home } from "./components/pages/Home";
import { Mixed } from "./components/pages/Mixxed";
import { StripePayment } from "./components/pages/Stripe";
import { PaymentComplete } from "./components/pages/PaymentComplete";


function App () {
  return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/mixxed" element={<Mixed />} />
				<Route path="/mixxed/stripe-payment" element={<StripePayment />} />
				<Route path="/mixxed/payment-complete" element={<PaymentComplete />} />
			</Routes>
		</Router>
	);
}

export default App;
