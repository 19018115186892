import React from 'react'
import "./style.css"
import { Link } from 'react-router-dom'
import Logo from "../../../assets/netmixx_logo_rb.png"

export const Navigation = () => {
  return (
		<nav>
			<div className="logo">
				<Link to="/">
					<img src={Logo} alt="NetMixx Logo" />
					<h1 className="logo-name">
						Net<span className="mixx">mixx</span>
					</h1>
				</Link>
			</div>
		</nav>
	);
}