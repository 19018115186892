import React from 'react';
import "./style.css";


export default function Alert({ children, onClick, color, bgColor }) {
	return (
		<div
			className="alert-container"
			style={{
				background: `${bgColor === "error" ? "#f8ebeb" : "#e6f8f0"}`,
				border: `1px solid ${color === "error" ? "#e00b0b" : "#25ed93"}`,
			}}
		>
			{/* <img src={} alt="" /> */}
			<div className="contain">
				<p
					style={{
						color: `${color === "error" ? "#e00b0b" : "#087846"}`,
					}}
				>
					{children}
				</p>
			</div>
			{/* <img src={} alt="" onClick={onClick} /> */}
		</div>
	);
}
