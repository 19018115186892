import React, { useState, useEffect } from "react";
import "./style.css";
import { loadStripe } from "@stripe/stripe-js";
import {
	Elements,
	useStripe,
	useElements,
	PaymentElement,
} from "@stripe/react-stripe-js";
import Logo from "../../../assets/netmixx_logo_rb.png";
import { useLocation } from "react-router-dom";
import axios from "axios";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const StripePayment = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const productType = queryParams.get("productType");
	const [stripeIntent, setStripeIntent] = useState(null);
	const [showEmail, setShowEmail] = useState(false);

	useEffect(() => {
		// console.log("productType", productType);
		// Fetch the client secret from the backend
		const fetchPaymentIntent = async () => {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/payment-intent`,
					{productType}, {
					headers: { "Content-Type": "application/json" },
				}
				);
				const data = await response.data;
				console.log("fetchPaymentIntent data", data);
				setStripeIntent(data);
			} catch (err) {
				console.error("Error fetching payment intent", err);
			}
		};

		fetchPaymentIntent();
	}, [productType]); // Empty dependency array ensures this runs only once after the component mounts

	return (
		stripeIntent && (
			<Elements
				stripe={stripePromise}
				options={{ clientSecret: stripeIntent.clientSecret }}
			>
				<PaymentComponent
					showEmail={showEmail}
					setShowEmail={setShowEmail}
					stripeIntent={stripeIntent}
				/>
			</Elements>
		)
	);
};

export default StripePayment;

const PaymentComponent = ({ showEmail, setShowEmail, stripeIntent }) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const batch_id = queryParams.get("batch_id");
	const stripe = useStripe();
	const elements = useElements();
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState("");

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);

		if (!stripe || !elements || !email) {
			setIsLoading(false);
			return;
		}

		const result = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${
					process.env.REACT_APP_URL
				}/mixxed/payment-complete?email=${encodeURIComponent(
					email
				)}&batch_id=${encodeURIComponent(batch_id)}`,
				// payment_method_data: {},
				// metadata: {
				// 	batch_id: batch_id,
				// 	email: email,
				// },
			},
		});

		console.log("Here is the payment confimation result:", result);

		if (result.error) {
			console.log(result.error.message);
			setIsLoading(false);
		} else {
			// Handle successful payment
			setIsLoading(false);
		}
	};

	return (
		<div style={{ padding: "2rem" }}>
			<div className="payment-container">
				{showEmail && (
					<div className="text-container">
						<div className="logo-container">
							<img src={Logo} alt="" />
							<p>NetMixx</p>
						</div>

						<div className="instruct-text">
							<h1>
								Pay <span>NetMixx</span>
							</h1>
							<p>${stripeIntent.amount / 100}.00</p>
						</div>
						<div className="details-card">
							<div className="payment-details">
								<div className="payment-text">
									<h5>{stripeIntent.product_name}</h5>
									<h6>{stripeIntent.product_description}</h6>
								</div>
								<p>${stripeIntent.amount / 100}.00</p>
							</div>
							<div className="total-container">
								<h5>Total</h5>
								<p>${stripeIntent.amount / 100}.00</p>
							</div>
						</div>
						<h3>Do not refresh this page until payment is complete</h3>
					</div>
				)}

				<form onSubmit={handleSubmit}>
					<PaymentElement
						onReady={() => {
							setShowEmail(true);
							console.log("PaymentElement is ready!");
						}}
						onLoaderror="" // set up text when component fails to load
					/>

					{showEmail && (
						<>
							<div className="form-group">
								<label htmlFor="email">Email Address</label>
								<input
									type="email"
									id="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Email@email.com"
									required
								/>
							</div>
							<button disabled={!stripe || isLoading} id="submit">
								{isLoading
									? "Processing..."
									: // <div className="spinner" id="spinner"></div>
									  "Pay now"}
							</button>
						</>
					)}
				</form>
			</div>
		</div>
	);
};
