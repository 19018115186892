import React from 'react'
import "./style.css"
import { Navigation } from '../Navigation';

export const DashboardLayout = ({ children, style }) => {
	return (
		<div>
			<Navigation />

			<div className="main-content" style={style}>
				{children}
			</div>
		</div>
	);
};
