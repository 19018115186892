import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import "./style.css";
import { LoadingModal } from "../../Layout/LoadingModal";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Alert from "../../Alert";
import { useLocation, useNavigate } from "react-router-dom";

export const Home = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const remixAlert = location.state?.showAlert || false;
	const [mainAudio, setMainAudio] = useState([]);
	const [adlibAudio, setAdlibAudio] = useState([]);
	const [beatAudio, setBeatAudio] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [mixSuccess, setMixSuccess] = useState(false);
	// const [finalResult, setFinalResult] = useState(null);
	const [masterAudio, setMasterAudio] = useState([]);
	const [isMasterAudio, setIsMasterAudio] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [showRemixAlert, setShowRemixAlert] = useState(remixAlert);
	const [alertMessage, setAlertMessage] = useState(""); // State to hold the alert message
	const [product, setProduct] = useState("mix_and_master");

	// Hide the remix alert after a timeout
	useEffect(() => {
		if (showRemixAlert) {
			const timer = setTimeout(() => {
				setShowRemixAlert(false);
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [showRemixAlert]);

	useEffect(() => {
		// Helper function to convert file size to MB
		const convertSizeToMB = (size) => size / (1024 * 1024);

		// Filter out files that exceed the size limit
		const filterFilesBySize = (files) => {
			if (!files) return [];
			return files.filter((file) => {
				const fileSizeMB = convertSizeToMB(file.size);
				if (fileSizeMB > 30) {
					setAlertMessage(
						`File "${file.name}" exceeds the 30MB limit and has been removed.`
					);
					setShowAlert(true);
					return false; // Exclude this file
				}
				return true; // Keep this file
			});
		};

		// Filter files for each category
		const filteredMainAudio = filterFilesBySize(mainAudio);
		const filteredAdlibAudio = filterFilesBySize(adlibAudio);
		const filteredBeatAudio = filterFilesBySize(beatAudio);
		const filteredMasterAudio = filterFilesBySize(masterAudio);

		// Update the state with filtered files
		if (filteredMainAudio.length !== mainAudio.length)
			setMainAudio(filteredMainAudio);
		if (filteredAdlibAudio.length !== adlibAudio.length)
			setAdlibAudio(filteredAdlibAudio);
		if (filteredBeatAudio.length !== beatAudio.length)
			setBeatAudio(filteredBeatAudio);
		if (filteredMasterAudio.length !== masterAudio.length)
			setMasterAudio(filteredMasterAudio);

		// Hide the alert after a timeout
		if (showAlert) {
			setTimeout(() => {
				setShowAlert(false);
			}, 3000);
		}

		// If any file is over the limit, prevent further action
		if (
			filteredMainAudio.length !== mainAudio.length ||
			filteredAdlibAudio.length !== adlibAudio.length ||
			filteredBeatAudio.length !== beatAudio.length ||
			filteredMasterAudio.length !== masterAudio.length
		) {
			setIsLoading(false);
		}
	}, [showAlert, mainAudio, adlibAudio, beatAudio, masterAudio]);

	const handleMixFiles = async (file, batchId, name) => {
		const newFileName = `${name}_${file.name}`;
		const renamedFile = new File([file], newFileName, { type: file.type });

		console.log(renamedFile);
		const mixFormData = new FormData();
		mixFormData.append("file", renamedFile);
		mixFormData.append("batch_id", batchId);

		try {
			const mixResponse = await axios.post(
				`${process.env.REACT_APP_API_URL}/mix`,
				mixFormData,
				{
					headers: { "Content-Type": "multipart/form-data" },
				}
			);
			console.log(`File ${name} mixed successfully:`, mixResponse.data);
		} catch (error) {
			console.error(`Failed to mix file ${name}:`, error);
		}
	};

	const handleMasterFile = async (file, batchId, name) => {
		const newFileName = `${name}_${file.name}`;
		const renamedFile = new File([file], newFileName, { type: file.type });
		console.log(renamedFile);
		const masterFormData = new FormData();
		masterFormData.append("file", renamedFile);
		masterFormData.append("batch_id", batchId);

		try {
			const masterResponse = await axios.post(
				`${process.env.REACT_APP_API_URL}/master`,
				masterFormData,
				{
					headers: { "Content-Type": "multipart/form-data" },
					responseType: "blob",
				}
			);
			console.log(`File ${name} successfully:`, masterResponse);
			const blob = new Blob([masterResponse.data], { type: "audio/wav" });
			const url = URL.createObjectURL(blob);
			setMixSuccess(true);

			navigate("/mixxed", {
				state: {
					result: {
						audio: url,
						batch_id: batchId,
					},
					product: product,
				},
			});
		} catch (error) {
			setIsLoading(false);
			console.error(`Failed to master file ${name}:`, error);
		}
	};

	const handleCombineResponse = async (batchId) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/finalize`,
				{ batch_id: batchId },
				{
					headers: { "Content-Type": "multipart/form-data" },
					responseType: "blob",
				}
			);
			const blob = new Blob([response.data], { type: "audio/wav" });
			const url = URL.createObjectURL(blob);
			setMixSuccess(true);
			navigate("/mixxed", {
				state: {
					result: {
						audio: url,
						batch_id: batchId,
					},
					product: product,
				},
			});
		} catch (error) {
			setIsLoading(false);
			console.error("Failed to combine files:", error);
		}
	};

	const handleMix = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		if (
			mainAudio.length === 0 ||
			adlibAudio.length === 0 ||
			beatAudio.length === 0
		) {
			console.error("Please select all files before mixing.");
			setIsLoading(false);
			return;
		}

		if (showAlert) {
			setIsLoading(false);
			return;
		}

		const batchId = uuidv4();
		console.log("Mix batchId", batchId);
		try {
			const audioFiles = [
				{ name: "main", files: mainAudio },
				{ name: "adlib", files: adlibAudio },
				{ name: "beat", files: beatAudio },
			];

			for (const { name, files } of audioFiles) {
				for (const file of files) {
					await handleMixFiles(file, batchId, name);
				}
			}

			await handleCombineResponse(batchId);
		} catch (err) {
			setIsLoading(false);
			console.error("Error processing files:", err);
		}
	};

	const handleMaster = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		if (!masterAudio) {
			console.error("Please select a file before mastering.");
			setIsLoading(false);
			return;
		}

		if (showAlert) {
			setIsLoading(false);
			return;
		}

		const batchId = uuidv4();
		console.log("Master batchId", batchId);

		try {
			// Process all files one by one
			for (const file of masterAudio) {
				// Process the master file
				await handleMasterFile(file, batchId, "master");
			}

			// Handle success scenario
			console.log("Files processed successfully");
			// You can set additional state or trigger any other action here upon successful processing
		} catch (err) {
			setIsLoading(false);
			console.error("Error processing files:", err);
		}
	};

	const handleFileSelection = (event, setState, currentFiles) => {
		const newFiles = Array.from(event.target.files);

		// Filter out duplicate files
		const nonDuplicateFiles = newFiles.filter(
			(newFile) =>
				!currentFiles.some(
					(existingFile) =>
						existingFile.name === newFile.name &&
						existingFile.lastModified === newFile.lastModified
				)
		);

		// Update the state with non-duplicate files
		setState([...currentFiles, ...nonDuplicateFiles]);
	};

	// Function to append new files to the existing state
	// const handleFileSelection = (event, setState, currentFiles) => {
	// 	const newFiles = Array.from(event.target.files);
	// 	setState([...currentFiles, ...newFiles]);
	// };

	return (
		<DashboardLayout>
			{showAlert && (
				<Alert bgColor="error" color="error">
					{alertMessage}
				</Alert>
			)}

			{showRemixAlert && (
				<Alert bgColor="success" color="success">
					Thank you, we will review your suggestions & have a new mix sent
					shortly!”
				</Alert>
			)}

			{isLoading && (
				<LoadingModal
					mixSuccess={mixSuccess}
					setMixSuccess={setMixSuccess}
					setIsLoading={setIsLoading}
					product={product}
				/>
			)}
			<div className="container">
				<h1 className="mixx-title">
					{isMasterAudio ? "NETMIXX | Master Track" : "NETMIXX | Mixx Track"}
				</h1>
			</div>

			<div className="tab-container">
				<button
					style={
						!isMasterAudio
							? {
									background: "rgb(111, 0, 111)",
									borderRadius: "10px 0px 0px 10px",
							  }
							: { background: "transparent", borderRadius: "10px 0px 0px 10px" }
					}
					onClick={() => {
						setIsMasterAudio(false);
						setProduct("mix_and_master");
					}}
				>
					Mix
				</button>
				<button
					style={
						isMasterAudio
							? {
									background: "rgb(111, 0, 111)",
									borderRadius: "0px 10px 10px 0px",
							  }
							: { background: "transparent", borderRadius: "0px 10px 10px 0px" }
					}
					onClick={() => {
						setIsMasterAudio(true);
						setProduct("single_track_master");
					}}
				>
					Master
				</button>
			</div>

			<div className="upload-container" id="upload-container">
				<div className="upload-header">
					{isMasterAudio
						? "Drop Your Track - Give It The Perfect Touch"
						: "Drop Your Stems - Mix Your Next Hit"}
				</div>
				{isMasterAudio ? (
					<form className="upload-form" id="upload-form">
						<div className="file-upload">
							<label htmlFor="master">Master Vocal</label>
							<label htmlFor="master" className="custom-file-button">
								{masterAudio.length > 0
									? Array.from(masterAudio)
											.map((file) => file.name)
											.join(", ")
									: "Choose Files"}
								<input
									type="file"
									id="master"
									name="master"
									accept="audio/*"
									multiple
									style={{ display: "none" }}
									onChange={(e) =>
										handleFileSelection(e, setMasterAudio, masterAudio)
									}
								/>
							</label>
						</div>
						<div className="button-container">
							<button
								type="submit"
								className="mixx-button"
								onClick={handleMaster}
							>
								Master
							</button>
						</div>
					</form>
				) : (
					<form className="upload-form" id="upload-form">
						<div className="file-upload">
							<label htmlFor="main">Main Vocals</label>
							<label htmlFor="main" className="custom-file-button">
								{mainAudio.length > 0
									? Array.from(mainAudio)
											.map((file) => file.name)
											.join(", ")
									: "Choose Files"}
								<input
									type="file"
									id="main"
									name="main"
									accept="audio/*"
									multiple
									style={{ display: "none" }}
									onChange={(e) =>
										handleFileSelection(e, setMainAudio, mainAudio)
									}
								/>
							</label>
						</div>
						<div className="file-upload">
							<label htmlFor="adlib">Background Vocals</label>
							<label htmlFor="adlib" className="custom-file-button">
								{adlibAudio.length > 0
									? Array.from(adlibAudio)
											.map((file) => file.name)
											.join(", ")
									: "Choose Files"}
								<input
									type="file"
									id="adlib"
									name="adlib"
									accept="audio/*"
									multiple
									style={{ display: "none" }}
									onChange={(e) =>
										handleFileSelection(e, setAdlibAudio, adlibAudio)
									}
								/>
							</label>
						</div>
						<div className="file-upload">
							<label htmlFor="beat">Instrumental</label>
							<label htmlFor="beat" className="custom-file-button">
								{beatAudio.length > 0
									? Array.from(beatAudio)
											.map((file) => file.name)
											.join(", ")
									: "Choose Files"}
								<input
									type="file"
									id="beat"
									name="beat"
									accept="audio/*"
									multiple
									style={{ display: "none" }}
									onChange={(e) =>
										handleFileSelection(e, setBeatAudio, beatAudio)
									}
								/>
							</label>
						</div>
						<div className="button-container">
							<button type="submit" className="mixx-button" onClick={handleMix}>
								Mixx
							</button>
						</div>
					</form>
				)}
			</div>
		</DashboardLayout>
	);
};
