import React, { useEffect, useState } from "react";
import "./style.css";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import { useLocation } from "react-router-dom";
import axios from "axios";

export const PaymentComplete = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	// const email = queryParams.get("email");
	const batch_id = queryParams.get("batch_id");
	const [audio, setAudio] = useState(null);
	const fileName = "full_audio.wav";

	useEffect(() => {
		// Fetch the file link from the backend
		const fetchFileLink = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/get-complete-file-link/${batch_id}`,
					{
						responseType: "blob",
					}
				);

				console.log("Here is the download file:", response);
				const blob = new Blob([response.data], { type: "audio/wav" });
				const url = URL.createObjectURL(blob);

				if (url) {
					setAudio(url);

					// Automatically trigger the download after 3 seconds
					const link = document.createElement("a");
					link.href = url;
					link.download = fileName;

					const timer = setTimeout(() => {
						link.click();
					}, 3000);

					// Cleanup the timer when the component unmounts or audio changes
					return () => clearTimeout(timer);
				} else {
					console.error("Error fetching file link:", response.error);
				}
			} catch (error) {
				console.error("Fetch error:", error);
			}
		};

		if (batch_id) {
			fetchFileLink();
		}
	}, [batch_id]);

	const handleDownload = () => {
		if (audio) {
			const link = document.createElement("a");
			link.href = audio;
			link.download = fileName;
			link.click();
		}
	};

	return (
		<DashboardLayout>
			<div className="inner-container">
				<header>
					<h1>Thank You for Your Purchase!</h1>
				</header>
				<main>
					{!audio && (
						<div
							style={{
								marginTop: "3rem",
								display: "flex",
								alignItems: "center",
								flexDirection: "column",
								gap: "1rem",
							}}
						>
							<p style={{ paddingBottom: "1rem"}}>Download link will be available shortly</p>
							<div class="loader"></div>
						</div>
					)}
					{audio && (
						<div style={{ margin: "0.5rem 0rem" }}>
							<p>
								Download will begin automatically. If it doesn't start, click
								the button below.
							</p>
							<div style={{ marginTop: "2rem" }}>
								<button
									id="manual-download-btn"
									className="btn btn-primary"
									onClick={handleDownload}
								>
									Download Now
								</button>
							</div>
						</div>
					)}
				</main>
			</div>
		</DashboardLayout>
	);
};
