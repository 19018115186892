import React from "react";
import "./style.css";
// import { useNavigate } from "react-router-dom";

export const LoadingModal = ({
	mixSuccess,
	setMixSuccess,
	setIsLoading,
	finalResult
}) => {
	const handleProccedtoMixPage = () => {
		setIsLoading(false);
		setMixSuccess(false);
	};
	return (
		<div className="loading-container" id="loading-container">
			{!mixSuccess ? (
				<div>
					{/* <div className="loading-circle"></div> */}
					<div className="loader"></div>
					<br />
					<br />
					<div className="loaders">
						<p>Mixing your tracks...</p>
						<div className="words">
							<span className="word">Main Vocals</span>
							<span className="word">Instrumental</span>
							<span className="word">Background Vocals</span>
							<span className="word">Samples</span>
							<span className="word">Main Vocals</span>
						</div>
					</div>
				</div>
			) : (
				<div className="success-container">
					<h2>Files Processed Successfully!</h2>
					<p>
						Your files have been successfully mixed and combined. You can
						download the test file from the link below:
					</p>
					<a
						href={finalResult ? finalResult : "#"}
						download="final-audio.wav"
						target="_blank"
						rel="noreferrer"
					>
						Download Test File
					</a>
					<br />
					<br />
					<button onClick={handleProccedtoMixPage}>
						Proceed to Mixxed Page
					</button>
				</div>
			)}
		</div>
	);
};
